import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Fade from "react-reveal/Fade"
import Roll from "react-reveal/Roll"

const GlideInStyle = () => (
    <div className="container-xxl">
        <div className="row mt-4">
            <Fade delay={800} duration={1500}>
                <div className="col-12 mb-4 col-md-7 mb-md-0 ps-inside">
                    <h2 className="mb-3">
                        Glide around the white <br /> isle in style.
                    </h2>
                    <div className="me-md-5">
                        <p className="sub-title-purple">
                            Immerse yourself in a unique personalized experience and enjoy the stunning scenery of Ibiza from the
                            comfort of your own private yacht on the Mediterranean Sea.
                        </p>
                        <p className="mb-4">
                            Our unrivalled fleet of bespoke yachts will pair together to give you the ultimate holiday experience,
                            offering something for everyone, couples, family days, or just a day with the girls on the sea accompanied
                            by your favourite champagne, wine or beers whilst plugged into our Maliblue music beats and absorbing the
                            breath-taking journey to Formentera. Unbelievable island sunsets or clear blue waters around Es Vedra? We
                            promise you an exhilarating experience, at Maliblue we’ve got you covered!
                        </p>
                        <div>
                            <Link className="button fill" to="/about">
                                <span>more about us</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </Fade>
            <div className="col-12 px-0 col-md-5 ps-md-3 position-relative d-flex flex-column justify-content-center">
                <Fade right>
                    <StaticImage className="yacht-girl" src="../images/glide1.png" alt="Girl on yacht" />
                </Fade>
                <Roll left>
                    <StaticImage className="yacht-overlay" src="../images/yacht6.png" alt="yacht" />
                </Roll>
            </div>
        </div>
    </div>
)

export default GlideInStyle
