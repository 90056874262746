import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import AnchorTop from "../images/anchor-top.svg"
import AnchorBottom from "../images/anchor-bottom.svg"
import Fade from "react-reveal/Fade"

const MaliblueIsCalling = () => (
    <div className="container-xxl">
        <div className="row">
            <div className="col-12 pt-md-3 px-0 position-relative">
                <img src={AnchorTop} className="maliblue-anchor-top d-none d-md-block" alt="Anchor vector" />
                <Fade right>
                    <StaticImage className="girl-on-yacht" src="../images/maliblueiscalling.png" alt="Girl on yacht" />
                </Fade>
                <img src={AnchorBottom} className="maliblue-anchor-bottom d-none d-md-block" alt="Anchor vector" />
                <Fade delay={700} duration={1500}>
                    <h1 className="mb-0 text-white text-center text-md-start maliblue-is-calling">
                        Maliblue <br />
                        is calling!
                    </h1>
                </Fade>
            </div>
        </div>
    </div>
)

export default MaliblueIsCalling
