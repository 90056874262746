import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { CandyPants } from "../components/candyPants"
import ExperienceIbiza from "../components/experienceIbiza"
import FleetSwiper from "../components/fleetSwiper"
import { Footer } from "../components/footer"
import GlideInStyle from "../components/glideInStyle"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import MaliblueIsCalling from "../components/maliblueIsCalling"
import Newsletter from "../components/newsletter"
import Seo from "../components/seo"

const Dev = () => {
    const allYachtsData = useStaticQuery(graphql`
        query AllYachtsIndex {
            allYachtsJson(filter: { name: { ne: "default" } }) {
                nodes {
                    id
                    name
                    specification {
                        length
                        beam
                        draft
                        fuel
                        speed
                        berths
                    }
                    model
                    link
                    description {
                        first
                    }
                    features {
                        first
                        second
                    }
                    mainImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    type
                }
            }
        }
    `)
    const yachts = allYachtsData.allYachtsJson.nodes

    return (
        <Layout>
            <Seo title="Home" />
            <NavBar />

            <div className="top-gradient-container">
                <MaliblueIsCalling />
                <GlideInStyle />
            </div>
            <div className="helper-fleet">
                <FleetSwiper yachts={yachts} />
            </div>

            <ExperienceIbiza />
            <CandyPants />
            <Newsletter />
            <Footer />
        </Layout>
    )
}

export default Dev
