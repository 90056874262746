import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { BOOKING } from "../utils/constants"
import Fade from "react-reveal/Fade"

const ExperienceIbiza = () => (
    <div className="container-xxl">
        <div className="row gy-3">
            <div className="col-12 ps-0 col-md-6 col-xl-5">
                <Fade left>
                    <div className="position-relative">
                        <StaticImage className="water" src="../images/ibiza1.jpg" alt="water" />
                        <StaticImage className="overlay-people" src="../images/ibiza2.jpg" alt="people on yacht" />
                        <StaticImage className="overlay-water" src="../images/ibiza3.jpg" alt="sunset water" />
                        <div className="the-white-island d-md-none">The White Island</div>
                    </div>
                </Fade>
            </div>
            <Fade delay={700} duration={1500}>
                <div className="col-12 mb-2 col-md-6 mb-md-0 col-xl-7 d-flex flex-column justify-content-center pe-inside">
                    <div className="ps-md-5">
                        <div className="mb-3">
                            <span className="pre-title">SET SAIL</span>
                        </div>
                        <div className="mb-3">
                            <h2>
                                Experience <br /> Ibiza by sea
                            </h2>
                        </div>
                        <p className="mb-3">
                            Remixing the rules of global partying, Candypants are the beat behind some of the world’s wildest days and
                            nights. With events across the globe, the aim is to offer customers a partying experience unlike any other.
                        </p>
                        <div>
                            <Link className="button fill" to={BOOKING ? "/book" : "/book-an-experience"}>
                                <span>book an experience</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </Fade>
            <div className="the-white-island d-none d-md-block ">The White Island</div>
        </div>
    </div>
)

export default ExperienceIbiza
